@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  :root {
    --black: #29292b;
    --white: #ffffff;
    --red: 229, 57, 53;
    --orange: 251, 140, 0;
    --yellow: 253, 216, 53;
    --green: 67, 160, 71;
    --blue: 30, 136, 229;
    --indigo: 57, 73, 171;
    --black2: 40, 41, 44;
    --white2: 255, 255, 255;
  
    --theme-color: var(--black2);
  }
  
  body {
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Nunito', sans-serif;
    height: 100vh;
    /* margin-bottom: 50px; */
    background-color: var(--white);
    color: var(--black);
  }
  
  a {
    text-decoration: none;
    color: var(--black);
  }
  
  p {
    line-height: 1.7;
  }
  
  ul {
    list-style: none;
  }
  
  li {
    line-height: 2.2;
  }
  
  h1{
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  
  h1,
  h2 {
    font-weight: 600;
    color: var(--black);
    text-transform: uppercase;
  }
  
  h3 {
    font-weight: 500;
    color: var(--black);
  }
  
  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0 100px 50px 100px;
    position: relative;
    
  }
  h2{
    text-align: left;
  }
.container-bio{
    font-family: "DM Sans", sans-serif;
    background-color: black !important;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 15px;
    overflow: hidden;
}

body:has(.container-bio){
    background-color: black;
  }

.bio-header{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    height: 50px;
}

.bio-header-buttons{
    width: 100%;
}

.bio-title{
    font-size: 1.7rem;
}
.bio-titles h1{
    margin-bottom: 12px !important;
}

.bio-logo{
    width: 100px;
}

.bio-subtitle{
    text-align: center !important;
    margin-top: 40px;
    font-size: 1.3rem !important;
    color: white !important;
    text-transform: none;
    font-weight: normal !important;
    letter-spacing: 1px;
}
.bio-paragraph{
    text-align: center !important;
    font-size: 1rem !important;
    color: white !important;
    text-transform: none;
    font-weight: normal !important;
    letter-spacing: 1px;
}


.links-bio{
    width: 100%;
    margin-top: 20px;
}

.link-bio{
    height: 55px;
    margin: 20px 0;
    border-radius: 50px;
    text-align: center !important;
    color: black !important;
    background: white !important;
    letter-spacing: 1px;
    line-height: none;
    border: 1.5px solid white;
    transition: all .3s;
    cursor: pointer;
    
}
.link-actions{
    position: relative;
}

.delete-icon{
    cursor: pointer;
    transition: all .3s;
    position: absolute;
    color: black;
    right: 20px;
    top: 10px;
    z-index: 100;
}

.delete-icon:hover{
    transform: scale(1.2);
}
.edit-icon{
    cursor: pointer;
    transition: all .3s;
    position: absolute;
    color: black;
    right: 70px;
    top: 10px;
    z-index: 100;
}

.edit-icon:hover{
    transform: scale(1.2);
}

.edit-link-container{
    display: flex;
    gap: 30px;
}

.link-bio a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 100%;
   
}
.link-bio a p{
    font-size: .9rem;
    max-width: 65%;
    letter-spacing: .6px;
}





.image-bio-link{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    top: 1px;
    object-fit: cover;
}

.social-media{
    margin-top: 15px;
    gap: 70px;
}

.bio-icons{
    color: white !important;
}

.btn-links{
    margin: 30px 0;
}




.loadingSpinnerContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #000 transparent #555 transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
  }



.heading {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 50px;
    padding: 0 20px;
  }
  
  .heading p {
    color:  rgba(var(--theme-color));
  }


.form {
    width: 70%;
    margin: 0 auto;
  }

  .form-group {
    margin-bottom: 10px;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: inherit;
  }
  
  .input-form{
    width: 200px;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: inherit;
  }

  .input-form-center{
    width: 300px;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: inherit;
  }
  
  .textarea{
    min-width: 400px !important;
    min-height: 150px !important;
  }
  .textarea2{
    margin-top: 20px;
    min-width: 100% !important;
    min-height: 130px;
  }
  
  select{
    width: 200px;
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: inherit;
  }
  
  input[type=file]{
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    margin-bottom: 30px;
    font-family: inherit;
  }
  
  .form-group label {
    text-align: left;
    display: block;
    margin: 0 0 5px 3px;
  }

  .center-element{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    margin-bottom: 20px;
  }

  .btn {
    padding: 7px 12px;
    border: 1px solid var(--black);
    border-radius: 5px;
    background: var(--black);
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    appearance: button;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    box-shadow: 1.2px 1.5px 0px var(--black);
  
  }

  .btn-block {
    min-width: 100px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }


  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 2px solid rgba(var(--theme-color));
    margin-bottom: 50px;
    position: relative;
  }
  
  .header ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header ul li {
    margin-left: 20px;
  }
  
  .header ul li a {
    display: flex;
    align-items: center;
  }
  
  .header ul li a:hover {
    color: #777;
  }
  
  .header ul li a svg {
    margin-right: 5px;
  }


.btn2 {
  padding: 7px 12px;
  border: 1px solid var(--black);
  border-radius: 5px;
  background: var(--black);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

}

  .btn2{
    width: 50%;
    background-color: var(--black);
    color: var(--white);
    border: 1px solid var(--black);
    box-shadow: 1.2px 1.5px 0px var(--black);
  }
  .btn2:hover {
    transform: scale(0.98);
  }
  .home-container{
    width: 100%;
    padding-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }